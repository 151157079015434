/* eslint-disable react/no-danger */
import * as React from 'react';
import Seo from '../../components/Seo/Seo';
import Post from '../../components/Post/Post';
import * as styles from './BlogPostPage.module.scss';

const BlogPostPage = ({ serverData }) => {
    const { title, text } = serverData;
    return (
        <>
            <Seo title={title} description={text} />
            <div className={styles.productHead}>
                <div className={styles.container}>
                    {title}
                </div>
            </div>
            <div className={styles.container}>
                <Post title={title}>
                    <div className={styles.blogPost} dangerouslySetInnerHTML={{ __html: text }} />
                </Post>
            </div>
        </>
    );
};
export default BlogPostPage;
