import { Router } from '@reach/router';
import BlogPostPage from '../../pages/BlogPostPage/BlogPostPage';
import Layout from '../layout';

const ProductRouter = ({ serverData }) => (
    <Layout>
        <Router>
            <BlogPostPage serverData={serverData} path="/blog/:slug" />
        </Router>
    </Layout>
);

export default ProductRouter;

export async function getServerData({ params }) {
    const { slug } = params;
    try {
        const res = await fetch(`${process.env.GATSBY_API_URL}/api/blog/${slug}`);
        if (!res.ok) {
            throw new Error('Response failed');
        }
        return {
            props: await res.json(),
        };
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {},
        };
    }
}
